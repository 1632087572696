<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Add Content</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <!--                    <router-link :to="{name: 'select_brand'}"-->
          <!--                                 class="btn-&#45;&#45;cta light-blue btn-round">-->
          <!--                        <span>Go Back</span>-->
          <!--                    </router-link>-->
          <div :class="{'circles_width': $route.name === 'select_content'}" class="circles">
            <!--                        <span class="dotted_circle active"></span>-->
            <span class="dotted_circle active"></span>
            <span class="dotted_circle"></span>
          </div>

          <button @click.prevent="validateAndFinalize()"
                  class="btn---cta btn-blue btn-round">
            <span>Next</span>
          </button>
        </div>

        <div class="add-block-list">
          <!--<p class="desc">Only the first 3 blocks will be visible in your Bio profile. Upgrade to Premium to add more than 3 content blocks</p>-->
          <!-- existing content block section -->

          <!--
            <div class="add-block-item added-item" v-for="(section, index) in getBioLinkAdd.content_sections"
               :key="section.id">
            <div class="block-inner">
              <div class="item-top-area d-flex align-items-center">
                <div class="item-left">
                  <p class="title">
                    <i class="far " :class="config[section.type]['class']"></i>
                    <span>{{ section.title }}</span>
                  </p>
                </div>
                <div class="item-right ml-auto">

          <i class="far fa-pencil"
             v-tooltip.top-center="'Edit'"
             @click.prevent="editBioContentSection(section)"></i>
          <i class="far fa-trash" v-tooltip.top-center="'Remove'"
             @click="removeContent(section)"></i>
          <i v-if="section.type === 'rss'" class="far fa-refresh" v-tooltip.top-center="'Refresh Feed'"
             @click="refreshRssFeed(section._id)"></i>
        </div>
      </div>
      <div class="item-link-lists">
        <template v-if="section.type === 'links'">
          <bio-short-link-snippet :links="section.links"></bio-short-link-snippet>
        </template>
        <template v-if="section.type === 'social'">
          <bio-short-social-snippet
            :channels_option="section.channels_option" :channels="section.channels"></bio-short-social-snippet>
        </template>
        <template v-if="section.type === 'rss'">
          <bio-short-posts-snippet
            :posts="section.posts"></bio-short-posts-snippet>
        </template>
      </div>
    </div>
  </div> -->

          <draggable :list="getBioLinkAdd.content_sections" :move="checkMove" :disabled="!enabled" ghost-class="ghost"
                     @start="dragging = true" @end="dragging = false" v-model="getBioLinkAdd.content_sections">
            <div class="add-block-item added-item" v-for="(section, index) in getBioLinkAdd.content_sections"
                 :key="section.id">
              <div class="block_cursor block-inner">
                <div class="item-top-area d-flex align-items-center">
                  <div class="item-left">
                    <p class="title">
                      <i class="far " :class="config[section.type]['class']"></i>
                      <span class="text-break">{{ getBlockTagLine(section) }}</span>
                    </p>
                  </div>
                  <div class="item-right ml-auto">
                    <!--                                    <i class="far fa-sort" v-tooltip.top-center="'Sort'"></i>-->
                    <i v-if="section.type === 'rss'" class="far fa-refresh" v-tooltip.top-center="'Refresh Feed'"
                       @click="refreshRssFeed(section._id)"></i>
                    <i class="far fa-pencil"
                       v-tooltip.top-center="'Edit'"
                       @click.prevent="editBioContentSection(section)"></i>
                    <i class="far fa-trash" v-tooltip.top-center="'Remove'"
                       @click="removeContent(section)"></i>

                  </div>
                </div>
                <div class="item-link-lists">
                  <template v-if="section.type === 'links'">
                    <bio-short-link-snippet :links="section.links"></bio-short-link-snippet>
                  </template>
                  <template v-if="section.type === 'social'">
                    <bio-short-social-snippet
                      :channels_option="section.channels_option"
                      :channels="section.channels"></bio-short-social-snippet>
                  </template>
                  <template v-if="section.type === 'rss'">
                    <bio-short-posts-snippet
                      :posts="section.posts"></bio-short-posts-snippet>
                  </template>
                </div>
              </div>
            </div>
          </draggable>

          <div class="add-block-item "
               :class="{'input-field-error' :content_block_required} ">
            <div class=" block-inner">
              <p class="desc-text mb-15">Add a new block</p>
              <div class="block-list text-center mt-4 mb-4">
                <div class="block-item "
                     @click="navigateContentBlock('SET_BIO_CONTENT_SECTION_TYPE', 'social', 'content_social_block')"
                     data-cy="social-icons">
                  <i class="fal fa-ellipsis-h fw-500 d-flex align-items-center justify-content-center"></i>
                  <p>Social Icons</p>
                </div>

                <div @click="navigateContentBlock('SET_BIO_CONTENT_SECTION_TYPE', 'links','content_links_block')"
                     class="block-item" data-cy="links-block">
                  <i class="fal fa-bars d-flex align-items-center justify-content-center"></i>
                  <p>Links</p>
                </div>
                <div @click="navigateContentBlock('SET_BIO_RSS_CONTENT_SECTION_TYPE' ,'rss','content_rss_block')"
                     class="block-item" data-cy="rss-block">
                  <i class="fal fa-rss d-flex align-items-center justify-content-center"></i>
                  <p>RSS Feed</p>
                </div>
                <div @click="navigateContentBlock('SET_BIO_AUDIO_CONTENT_SECTION_TYPE', 'audio','content_audio_block')"
                     class="block-item" data-cy="rss-block">
                  <i class="fal fa-volume-up d-flex align-items-center justify-content-center"></i>
                  <p>Audio</p>
                </div>
              </div>
              <div class="block-list text-center">
                <div @click="navigateContentBlock('SET_BIO_VIDEO_CONTENT_SECTION_TYPE','video','content_video_block')"
                     class="block-item"
                     data-cy="rss-block">
                  <i class="fab fa-youtube d-flex align-items-center justify-content-center"></i>
                  <p>Video</p>
                </div>
                <div @click="navigateContentBlock('SET_BIO_CARD_CONTENT_SECTION_TYPE','card','content_card_block')"
                     class="block-item" data-cy="rss-block">
                  <i class="fal fa-image d-flex align-items-center justify-content-center"></i>
                  <p>Card</p>
                </div>
                <div @click="navigateContentBlock('SET_BIO_TEXT_CONTENT_SECTION_TYPE','text','content_text_block')"
                     class="block-item" data-cy="rss-block">
                  <i class="fal fa-text-size d-flex align-items-center justify-content-center"></i>
                  <p>Text</p>
                </div>
              </div>
            </div>

          </div>

          <!--                    <div class="btn-block  mt-3">-->
          <!--                        <router-link :to="{name: 'select_brand'}"-->
          <!--                                     class="btn-&#45;&#45;cta light-blue btn-round">-->
          <!--                            <span>Go Back</span>-->
          <!--                        </router-link>-->

          <!--                        <button @click.prevent="validateAndFinalize()"-->
          <!--                                class="btn-&#45;&#45;cta btn-blue btn-round">-->
          <!--                            <span>Next</span>-->
          <!--                        </button>-->

          <!--                    </div>-->

        </div>

      </div>

    </div>

    <!--        <draggable v-model="limitContentSections" group="items" @start="drag=true" @end="drag=false" hidden>-->
    <!--                        <div class="add-block-item " v-for="(item, index) in limitContentSections" :key="index" :class="{-->
    <!--                                'input-field-error' : index=== 0 && content_block_required} "-->
    <!--                             @click="$router.push({name:'select_block'})">-->
    <!--                            <div class=" block-inner">-->
    <!--                                <p class="desc-text">Tap to add content {{index}}</p>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--        </draggable>-->

  </div>

</template>
<script>

import {mapGetters, mapActions, mapMutations} from 'vuex'
import {bioLinkTypes} from '@/state/modules/mutation-types'
import BioShortenedURL from './BioShortenedURL'
import bioLinkMixin from '@/mixins/bio-link-mixin'
import BioShortSocialSnippet from './short/BioShortSocialSnippet'
import BioShortLinkSnippet from './short/BioShortLinkSnippet'
import BioShortPostsSnippet from './short/BioShortPostsSnippet'
import draggable from 'vuedraggable'
import {bio_content_type} from "../../../../../common/attributes";

export default (
  {
    mixins: [bioLinkMixin],
    components: {
      'bio-shortened-url': BioShortenedURL,
      BioShortSocialSnippet,
      BioShortLinkSnippet,
      BioShortPostsSnippet,
      draggable
    },
    data () {
      return {
        content_block_required: false,
        enabled: true,
        slickOptions: {
          infinite: false,
          slidesToShow: 1,
          asNavFor: this.$refs.slick,
          speed: 700,
          autoplay: false,
          dots: false,
          nextArrow: false,
          prevArrow: false
        },
        deleteCheck: false,
        config: {
          [bio_content_type.social]: {
            class: 'fa-globe',
            route: 'content_social_block',
            commit: 'SET_BIO_CONTENT_SECTION_ADD'
          },
          [bio_content_type.links]: {
            class: 'fa-link',
            route: 'content_links_block',
            commit: 'SET_BIO_CONTENT_SECTION_ADD'
          },
          [bio_content_type.rss]: {class: 'fa-rss', route: 'content_rss_block', commit: 'SET_RSS_CONTENT_SECTION_ADD'},
          [bio_content_type.audio]: {
            class: 'fa-music',
            route: 'content_audio_block',
            commit: 'SET_AUDIO_CONTENT_SECTION_ADD'
          },
          [bio_content_type.video]: {
            class: 'fa-film',
            route: 'content_video_block',
            commit: 'SET_VIDEO_CONTENT_SECTION_ADD'
          },
          [bio_content_type.card]: {
            class: 'fa-image',
            route: 'content_card_block',
            commit: 'SET_CARD_CONTENT_SECTION_ADD'
          },
          [bio_content_type.text]: {
            class: 'fa-text-size',
            route: 'content_text_block',
            commit: 'SET_TEXT_CONTENT_SECTION_ADD'
          },
        }
      }
    },
    async created () {
      await this.fetchWhitelabelAppSettings()
      this.$store.commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
      this.getBioLinkAdd.first_step_verify = true
      // if (!this.getBioLinkAdd.brand_id) this.$router.push({ name: 'select_content' })
      if (this.getDomains.items && this.getDomains.items.length == 0) {
        await this.fetchDomains()
      }

      // setting the default bio domain
      if(!this.getBioLinkAdd._id) {
        let domain = this.getDefaultDomainEnv.biolink

        // in case of whitelabel we will set the whitelabel domain
        if (this.getSiteDetails.isWhiteLabel) {
          domain = this.getBioDefaultCustomDomain
          this.$store.commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN, this.trimUrl(domain.url))
          if (domain && domain.url.substr(-1) !== '/') {
            domain.url = domain.url + '/'
          }
          this.$store.commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN_ID, domain._id)
        }

        this.setBioLinkDomain(domain)
      }

      await this.fetchPixels()
    },
    computed: {
      ...mapGetters(['getBioLinkAdd',
        'getIgnoreChanges',
        'getDomains',
        'getDefaultDomainEnv',
        'getBioLinks',
        'getBioLinkAudioSectionAdd']),

      limitContentSections () {
        const totalSections = 6
        return totalSections - this.getBioLinkAdd.content_sections.length
      }
    },
    methods: {
      ...mapActions([
        'fetchWhitelabelAppSettings',
        'refreshRssFeed',
        'fetchDomains',
        'setBioLinkDomain',
        'fetchPixels',
        'resetAudioContentSectionAdd',
        'resetVideoContentSectionAdd',
        'resetCardContentSectionAdd',
        'resetTextContentSectionAdd',
        'resetRssContentSectionAdd'
      ]),
      next () {
        this.$refs.slick.next()
      },
      async checkMove () {
        // let obj = await this.getBioLinkAdd.content_sections
        // setTimeout(this.$store.dispatch('updateBioContentSection', obj), 2000)
      },
      prev () {
        this.$refs.slick.prev()
      },

      /**
       * Getting the tag line of bio link block sections
       * @param section
       * @returns {string|*}
       */
      getBlockTagLine(section) {
        return section.type === 'text' ? 'Text' : section.title
      },

      validateAndFinalize () {
        if (this.getBioLinkAdd.content_sections.length === 0) {
          this.getBioLinkAdd.name = this.getBioLinkAdd.page_title
          this.content_block_required = true
          this.$store.dispatch('toastNotification', {
            message: 'You need to add atleast one content-widget to create the link.',
            type: 'error'
          })
          return
        }
        this.$router.push({name: 'bio_finalize'})
      },
      editBioContentSection (item) {
        const config = this.config[item.type]
        this.$store.commit(bioLinkTypes[config['commit']], item)
        this.$router.push({name: config['route']})
      },
      removeContent (section) {
        this.deleteCheck = true
        var self = this
        swal({
            title: 'Are you sure ?',
            type: 'warning',
            text: '',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, Cancel!',
            closeOnConfirm: true,
            customClass: 'sweetAlert_box',
            closeOnCancel: true,
            animation: false
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$store.dispatch('deleteBioContentSection', {_id: section._id})
            } else {
              swal()
            }
          })
      },
      navigateContentBlock (mutationType = '', type = '', routeName = '') {
        this.resetAudioContentSectionAdd()
        this.resetVideoContentSectionAdd()
        this.resetCardContentSectionAdd()
        this.resetTextContentSectionAdd()
        this.resetRssContentSectionAdd()
        this.$store.commit(bioLinkTypes[mutationType], type)
        this.$router.push({name: routeName})
      }
    },
    watch: {
      'getBioLinkAdd.content_sections.length' (value) {
        if (value > 0) this.content_block_required = false
      },
      'getBioLinkAdd.content_sections' () {
        if (this.getIgnoreChanges) {
          this.$store.dispatch('resetIgnoreChanges')
          return false
        }
        this.$store.dispatch('updateBioContentSection', this.getBioLinkAdd.content_sections)
      }
    }
  }
)
</script>
<style lang="less">

.block_cursor {
  cursor: move !important;
}

.slick-slide {
  width: 100% !important;
}

.bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
  width: 620px;
  text-align: center;
  display: inline-block;
}

.mb-15 {
  margin-bottom: 15px;
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.circles_width {
  width: 624px !important;
}
</style>
